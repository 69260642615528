import { Elm } from './Main.elm';
import 'configurable-date-input-polyfill';
import Papa from 'papaparse';
import Dexie from 'dexie';

console.log("Starting app...");
let db = new Dexie("ClinicalTermDatabase");
let app = Elm.Main.init({
  node: document.getElementById('root')
});

db.version(1).stores({
  readcode: '++id, Readcode, Description',
  snomed: '++id, ConceptSCTID, PreferredTerm, ReadCode, ReadTerm'
});

db.open();

db.on('ready', function (db) {
  // on('ready') event will fire when database is open but 
  // before any other queued operations start executing.
  // By returning a Promise from this event,
  // the framework will wait until promise completes before
  // resuming any queued database operations.
  // Let's start by using the count() method to detect if
  // database has already been populated.
  return db.readcode.count(async function (count) {
    if (count > 0) {
      console.log("Database already populated");
    } else {
      console.log("Database is empty. Populating now...");
      await parseCsvFile("/readcode.csv", 'readcode');
      await parseCsvFile("/snomed.csv", 'snomed');
      console.log("Done populating.");
    }
    app.ports.receive_db_ready.send(true);
  });
});

async function parseCsvFile(url, storeName) {
  Papa.parse(url, {
    header: true,
    skipEmptyLines: true,
    download: true,
    complete: function (results) {
      const data = results.data;  // Array of objects parsed from CSV
      console.log(`Parsed ${data.length} rows from ${url}`);
      console.log(`For example, first row:`, data[0]);
      return db[storeName].bulkAdd(data);
    },
    error: function (error) {
      console.error(`Error parsing ${url.name}:`, error);
    }
  });
}

app.ports.send_readcode_query.subscribe(function (query) {
  db.readcode.filter((readcode) => {
    // filter by Readcode or Description, case insensitive
    // return true if each space-separated word in query is found in Description
    query = query.toLowerCase();
    let queryWords = query.split(" ");
    let description = readcode.Description.toLowerCase();
    return queryWords.every(word => description.includes(word));
  }).toArray().then(function (results) {
    app.ports.receive_readcode.send(results);
  });
})

app.ports.send_snomed_query.subscribe(function (query) {
  db.snomed.filter((snomed) => {
    // filter by preferred term or read term, case insensitive
    // strip whitespace and convert to lowercase
    // return true if each space-separated word in query is found in either preferred term or read term

    query = query.toLowerCase();
    let queryWords = query.split(" ");
    let preferredTerm = snomed.PreferredTerm.toLowerCase();
    let readTerm = snomed.ReadTerm.toLowerCase();
    return queryWords.every(word => preferredTerm.includes(word) || readTerm.includes(word));
  })
    .toArray()
    .then(function (results) {
      app.ports.receive_snomed.send(results);
    });
})

app.ports.init_tooltips.subscribe(function () {
  $('[data-toggle="tooltip"]').tooltip()
  console.log("Tooltips initialized");
})
