port module Main exposing (Model, Msg(..), ReadcodeEntry, SnomedEntry, init, main, readcodeListDecoder, snomedListDecoder, subscriptions, update, view, viewResult, viewStatus)

import Array exposing (Array)
import Browser
import Date
import Debug
import Disclaimer
import Html as H exposing (Html, a, button, p, text)
import Html.Attributes as A exposing (attribute, class, href, id)
import Html.Attributes.Extra exposing (role)
import Html.Events as E
import Html.Events.Extra exposing (onChange)
import Json.Decode exposing (Decoder, Value, array, decodeValue, field, map2, map4, string)
import List.Extra exposing (uniqueBy)
import Process
import Task
import Time


main =
    Browser.element
        { init = init
        , update = update
        , subscriptions = subscriptions
        , view = view
        }



-- MODEL


type alias Model =
    { snomed : Array SnomedEntry
    , readcode : Array ReadcodeEntry
    , showReadCode : Bool
    , dbReady : Bool
    , query : String
    , snomedWaiting : Bool
    , readcodeWaiting : Bool
    , sickDays : Int
    , buttonUnit : Date.Unit
    , today : Date.Date
    , injuryDate : Date.Date
    }


init : () -> ( Model, Cmd Msg )
init _ =
    ( { snomed = Array.empty
      , readcode = Array.empty
      , showReadCode = False
      , dbReady = False
      , query = ""
      , snomedWaiting = False
      , readcodeWaiting = False
      , sickDays = 3
      , buttonUnit = Date.Days
      , today = Date.fromPosix Time.utc (Time.millisToPosix 0)
      , injuryDate = Date.fromPosix Time.utc (Time.millisToPosix 0)
      }
    , Cmd.batch
        [ Task.perform
            GotTodayDate
            Date.today
        , init_tooltips ()
        ]
    )



-- Decoders


type alias SnomedEntry =
    { conceptId : String
    , preferredName : String
    , readTerm : String
    , readCode : String
    }


type alias ReadcodeEntry =
    { readTerm : String
    , readCode : String
    }


snomedListDecoder : Decoder (Array SnomedEntry)
snomedListDecoder =
    array <|
        map4 SnomedEntry
            (field "ConceptSCTID" string)
            (field "PreferredTerm" string)
            (field "ReadTerm" string)
            (field "ReadCode" string)


readcodeListDecoder : Decoder (Array ReadcodeEntry)
readcodeListDecoder =
    array <|
        map2 ReadcodeEntry
            (field "Description" string)
            (field "ReadCode" string)



-- UPDATE


type Msg
    = QueryChanged String
    | DebouncedQuery String
    | Calculate Date.Unit Int
    | ChangeUnit Date.Unit
    | ChangeLength String
    | ChangeInjuryDate String
    | ReceivedSnomed Value
    | ReceivedReadcode Value
    | ReceiveDbReady Bool
    | ShowReadCode Bool
    | GotTodayDate Date.Date


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        GotTodayDate today ->
            ( { model | today = today, injuryDate = today }
            , Cmd.none
            )

        QueryChanged string ->
            let
                debounceCmd =
                    Task.perform (always (DebouncedQuery string)) (Process.sleep 500)
            in
            ( { model
                | query = string
                , snomed = Array.empty
                , readcode = Array.empty
              }
            , Cmd.batch [ debounceCmd ]
            )

        DebouncedQuery oldQuery ->
            if model.query == oldQuery && String.length model.query >= 3 then
                ( { model
                    | snomedWaiting = True
                    , readcodeWaiting = True
                  }
                , Cmd.batch
                    [ send_snomed_query model.query
                    , send_readcode_query model.query
                    ]
                )

            else
                ( model
                , Cmd.none
                )

        Calculate unit length ->
            ( { model | sickDays = length, buttonUnit = unit }
            , Cmd.none
            )

        ChangeUnit unit ->
            ( { model | buttonUnit = unit }
            , Cmd.none
            )

        ChangeLength length_string ->
            let
                length =
                    String.toInt length_string
            in
            case length of
                Just l ->
                    ( { model | sickDays = l }
                    , Cmd.none
                    )

                Nothing ->
                    ( { model | sickDays = 0 }
                    , Cmd.none
                    )

        ChangeInjuryDate dateString ->
            ( { model
                | injuryDate =
                    Result.withDefault model.today <|
                        Date.fromIsoString
                            dateString
              }
            , Cmd.none
            )

        ReceiveDbReady ready ->
            ( { model | dbReady = ready }
            , Cmd.none
            )

        ReceivedReadcode value ->
            let
                result =
                    decodeValue readcodeListDecoder value
            in
            case result of
                Ok readcode_array ->
                    ( { model | readcode = readcode_array, readcodeWaiting = False }
                    , Cmd.none
                    )

                Err _ ->
                    ( model
                    , Cmd.none
                    )

        ReceivedSnomed value ->
            let
                result =
                    decodeValue snomedListDecoder value
            in
            case result of
                Ok snomed_array ->
                    ( { model | snomed = snomed_array, snomedWaiting = False }
                    , Cmd.none
                    )

                Err _ ->
                    ( model
                    , Cmd.none
                    )

        ShowReadCode show ->
            ( { model | showReadCode = show }
            , Cmd.none
            )



-- PORTS


port send_snomed_query : String -> Cmd msg


port receive_snomed : (Value -> msg) -> Sub msg


port send_readcode_query : String -> Cmd msg


port receive_readcode : (Value -> msg) -> Sub msg


port receive_db_ready : (Bool -> msg) -> Sub msg


port init_tooltips : () -> Cmd msg



-- SUBSCRIPTIONS


subscriptions _ =
    Sub.batch
        [ receive_snomed ReceivedSnomed
        , receive_readcode ReceivedReadcode
        , receive_db_ready ReceiveDbReady
        ]



-- VIEW


view : Model -> Html Msg
view model =
    H.div [ A.class "container" ]
        [ viewPromotion
        , H.div [ class "content" ]
            [ viewMainInterface model
            , viewResult model
            ]
        , viewFooter
        , viewDisclaimer
        ]


viewMainInterface : Model -> Html Msg
viewMainInterface model =
    H.div [ A.class "jumbotron" ]
        [ H.h1 [ A.class "text-center" ] [ text "Easy Read Code", H.small [] [ text " 2025" ] ]
        , viewStatus model
        , viewSearchBar
        , viewDateCalculator model.buttonUnit model.sickDays model.today model.injuryDate
        ]


viewPromotion =
    H.div [ A.class "alert alert-info promotion text-center" ]
        [ text " 😖 Preparing for ACEM Primary or GSSE? "
        , text "Checkout my new project "
        , a
            [ A.href "https://www.acetheexam.co.nz"
            , A.target "_blank"
            , A.class "acetheexam"
            ]
            [ text "Ace the Exam" ]
        , text " 🤘"
        ]


viewDateCalculator : Date.Unit -> Int -> Date.Date -> Date.Date -> Html Msg
viewDateCalculator unit length today injuryDate =
    H.div [ A.class "row date-group" ]
        [ H.div [ A.class "col-xs-8 col-xs-offset-2 col-sm-6 col-sm-offset-0 col-md-6 col-lg-4" ]
            [ H.h5 [ A.class "text-center" ] [ text "Injury date" ]
            , H.input
                [ A.attribute "type" "date"
                , A.class "form-control text-center date-polyfill"
                , onChange ChangeInjuryDate
                , A.id "starting-date-input"
                , A.value <| Date.toIsoString injuryDate
                ]
                []
            ]
        , H.div [ A.class "col-xs-12 col-sm-6 col-md-6 col-lg-4 text-center" ]
            [ H.h5 [ A.class "text-center" ]
                [ text <| "unfit for work for " ++ String.fromInt length ++ " " ++ dateUnitToString unit ]
            , H.div [ A.class "row" ]
                [ H.div [ A.class "input-group-btn ", A.attribute "role" "group" ]
                    [ button
                        [ A.type_ "button"
                        , A.class "btn btn-default"
                        , E.onClick (Calculate Date.Days 3)
                        ]
                        [ text "3 days" ]
                    , button
                        [ A.type_ "button"
                        , A.class "btn btn-default"
                        , E.onClick (Calculate Date.Days 7)
                        ]
                        [ text "7 days" ]
                    , button
                        [ A.type_ "button"
                        , A.class "btn btn-default"
                        , E.onClick (Calculate Date.Days 10)
                        ]
                        [ text "10 days" ]
                    , button
                        [ A.type_ "button"
                        , A.class "btn btn-default"
                        , E.onClick (Calculate Date.Days 14)
                        ]
                        [ text "14 days" ]
                    ]
                , H.div [ A.class "input-group input-group-sm input-date-length" ]
                    [ H.input
                        [ A.type_ "number"
                        , A.class "form-control"
                        , A.placeholder ""
                        , E.onInput ChangeLength
                        ]
                        []
                    , H.div [ A.class "input-group-btn" ]
                        [ button
                            [ A.type_ "button"
                            , A.class "btn btn-default dropdown-toggle"
                            , A.attribute "data-toggle" "dropdown"
                            ]
                            [ text (dateUnitToString unit)
                            , H.span [ A.class "caret" ] []
                            ]
                        , H.ul [ A.class "dropdown-menu dropdown-menu-right" ]
                            [ H.li [] [ a [ A.href "#", E.onClick (ChangeUnit Date.Days) ] [ text "days(s)" ] ]
                            , H.li [] [ a [ A.href "#", E.onClick (ChangeUnit Date.Weeks) ] [ text "week(s)" ] ]
                            , H.li [] [ a [ A.href "#", E.onClick (ChangeUnit Date.Months) ] [ text "month(s)" ] ]
                            ]
                        ]
                    ]
                ]
            ]
        , H.div [ A.class "col-xs-8 col-xs-offset-2 col-lg-offset-0 col-lg-4" ]
            [ H.h5 [ A.class "text-center" ] [ text " until (inclusive of injury date):" ]
            , H.div [ A.class "well well-sm text-center" ]
                [ H.strong [] [ text (calculateDateSinceInjury injuryDate length unit) ]
                ]
            ]
        ]


dateUnitToString : Date.Unit -> String
dateUnitToString unit =
    case unit of
        Date.Days ->
            "day(s)"

        Date.Weeks ->
            "week(s)"

        Date.Months ->
            "month(s)"

        _ ->
            ""


calculateDateSinceInjury : Date.Date -> Int -> Date.Unit -> String
calculateDateSinceInjury injuryDate length unit =
    let
        endDate =
            case unit of
                Date.Days ->
                    Date.add unit length injuryDate

                Date.Weeks ->
                    Date.add unit length injuryDate

                Date.Months ->
                    Date.add unit length injuryDate

                _ ->
                    injuryDate
    in
    Date.format "EEEE d/MM/y" <| Date.add Date.Days -1 endDate


viewSearchBar : Html Msg
viewSearchBar =
    H.input
        [ A.type_ "text"
        , A.autofocus True
        , A.class "search form-control input-lg"
        , A.placeholder "Enter a diagnosis, e.g. hand laceration"
        , E.onInput QueryChanged
        ]
        []


viewStatus : Model -> Html Msg
viewStatus model =
    let
        loaded =
            model.dbReady
    in
    H.h5 [ A.class "text-right" ]
        [ text "Database Status: "
        , H.span
            [ A.class
                ("glyphicon "
                    ++ (if loaded then
                            "glyphicon-ok-sign"

                        else
                            "glyphicon-refresh spinning"
                       )
                )
            , A.style "color"
                (if loaded then
                    "green"

                 else
                    "orange"
                )
            , A.attribute "data-toggle" "tooltip"
            , A.attribute "data-placement" "top"
            , A.attribute "data-original-title"
                (if loaded then
                    "Database fully loaded"

                 else
                    "Database loading..."
                )
            ]
            []
        ]


viewResult : Model -> Html Msg
viewResult model =
    let
        waiting =
            model.snomedWaiting || model.readcodeWaiting

        filteredReadCode =
            let
                readCodeInSnomed =
                    Array.toList model.snomed |> List.map .readCode |> uniqueBy identity
            in
            Array.filter (\entry -> not (List.member entry.readCode readCodeInSnomed)) model.readcode

        showReadCodeButton =
            not waiting && Array.length model.snomed > 0 && Array.length filteredReadCode > 0

        rows =
            if Array.length model.snomed > 0 then
                (List.map viewSnomedTableRow <| Array.toList model.snomed)
                    ++ (if model.showReadCode then
                            List.map viewReadCodeTableRow <| Array.toList filteredReadCode

                        else
                            []
                       )

            else
                List.map viewReadCodeTableRow <| Array.toList filteredReadCode

        results =
            if waiting then
                []

            else
                rows
    in
    H.div [ A.class "results col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2" ]
        [ H.table [ A.class "table table-bordered table-hover table-striped" ]
            [ H.thead []
                [ H.tr []
                    [ H.th [ class "col-sm-6" ] [ text "Diagnosis" ]
                    , H.th [ class "col-sm-3" ]
                        [ text "SNOMED-CT "
                        , H.span
                            [ class "glyphicon glyphicon-question-sign"
                            , A.attribute "data-toggle" "tooltip"
                            , A.attribute "data-placement" "top"
                            , A.attribute "data-original-title" "SNOMED Clinical Terms (CT) is a new clinical terminology system, which has been endorsed by the New Zealand Ministry of Health for use in the health and disability sector. It will replace the UK Read clinical codes, which are no longer supported."
                            ]
                            []
                        ]
                    , H.th [ class "col-sm-3" ] [ text "Read Code" ]
                    ]
                ]
            , H.tbody [] results
            ]
        , if waiting then
            H.div [ class "text-center" ]
                [ text "Loading... "
                , H.span [ class "glyphicon glyphicon-refresh spinning" ] []
                ]

          else
            H.div [] []
        , if showReadCodeButton then
            viewShowReadCodeButton model.showReadCode

          else
            H.div [] []
        ]


viewShowReadCodeButton : Bool -> Html Msg
viewShowReadCodeButton showReadCode =
    button
        [ class "btn btn-default col-md-4 col-md-offset-4"
        , E.onClick (ShowReadCode (not showReadCode))
        ]
        [ text
            (if showReadCode then
                "Hide"

             else
                "Show"
            )
        , text " less commonly used codes "
        , H.span
            [ class <|
                "glyphicon"
                    ++ (if showReadCode then
                            " glyphicon-triangle-top"

                        else
                            " glyphicon-triangle-bottom"
                       )
            ]
            []
        ]


viewSnomedTableRow : SnomedEntry -> Html Msg
viewSnomedTableRow entry =
    H.tr []
        [ H.td [] [ H.strong [] [ text entry.preferredName ] ]
        , H.td [] [ text entry.conceptId ]
        , H.td [] [ text entry.readCode ]
        ]


viewReadCodeTableRow : ReadcodeEntry -> Html Msg
viewReadCodeTableRow entry =
    H.tr []
        [ H.td [] [ text entry.readTerm ]
        , H.td [] [ text "" ]
        , H.td [] [ text entry.readCode ]
        ]


viewDisclaimer : Html msg
viewDisclaimer =
    H.div [ id "disclaimer", class "modal fade", role "dialog" ]
        [ H.div [ class "modal-dialog" ]
            [ H.div [ class "modal-content" ]
                [ H.div [ class "modal-header" ]
                    [ button [ class "close", attribute "data-dismiss" "modal" ] [ text "×" ]
                    , H.h4 [ class "modal-title text-center" ] [ text "Disclaimer and Copyright" ]
                    ]
                , H.div [ class "modal-body" ]
                    [ H.div [ class "disclaimer" ]
                        (List.map (\par -> p [] [ text par ]) Disclaimer.content)
                    ]
                ]
            ]
        ]


viewFooter =
    H.div [ class "footer" ]
        [ H.div [ class "row" ]
            [ H.div [ class "col-xs-4" ]
                [ a
                    [ href "#disclaimer"
                    , class "text-center"
                    , attribute "data-toggle" "modal"
                    , attribute "data-target" "#disclaimer"
                    ]
                    [ text "Disclaimer and copyright"
                    ]
                ]
            , H.div [ class "col-xs-4" ]
                [ p [ class "text-muted text-center" ]
                    [ text "Built with                "
                    , H.span
                        [ attribute "aria-hidden" "true"
                        , class "glyphicon glyphicon-heart"
                        , attribute "data-placement" "top"
                        , attribute "data-toggle" "tooltip"
                        , attribute "style" "color:red; margin-right: 4px"
                        ]
                        []
                    , text "by Tubo Shi"
                    , text " | "
                    , a [ href "https://www.buymeacoffee.com/tuboshi" ] [ text "Buy me a coffee?" ]
                    ]
                ]
            , H.div [ class "col-xs-4" ]
                [ p [ class "text-center" ]
                    [ a [ href "mailto:tubo@tubo.nz?subject=Easy Read Code User" ]
                        [ text "Comments and feedback" ]
                    ]
                ]
            ]
        ]
